<template>
    <nav class="navbar navbar-expand-lg bg-common text-white">
        
        

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            
        </div>
    </nav>
    <div class="main-cotainer">
        <div class="row">
            <div class="col-md-6 p-3  order-2 order-md-1">
                <div class="form-group first" style="text-align: center;">
                    <label style="color:#AF0A0C ;"><h2><b>Its Free! Get it now!</b></h2></label><br>
                    <label>For Pharmacy Stores in India!</label>
                </div>
                <div style="border: 2px solid #AF0A0C;padding: 10px;border-radius: 0.5rem;background-color:#AF0A0C ;">
                <div class="form-group first">
                    <label for="username" class="required">Your Name Please</label>
                    <input name="username" type="text" class="form-control" v-model="username" placeholder="" id="" required>

                </div>
                <div class="form-group last mb-3">
                    <label for="email" class="required">Your Email Please</label> <span class="mb-4 " style="margin-left: 52%;"></span>
                    <input name="email" type="email" class="form-control" v-model="email" placeholder="" id="" required>

                </div>
                <div class="form-group last mb-3">
                    <label for="phone" class="required">Your Phone Number</label>
                    <input name="phone" type="phone" class="form-control" v-model="phone" placeholder="" id="" required>

                </div>
                <div style="text-align: center;margin-top: 5%;">
                    <input v-on:click="Submit" type="submit" value="Send me Free eBook now!" style="font-size: large;background-color: #ececec;color: black;" class="btn btn-common">
                    <div class="form-group first" style="text-align: center;">
                        <label style="font-size: small;color: #fff;">eBook will be delivered on your Whatsapp </label>
                    </div>
                </div>
            </div>
                <!-- <div class="form-group last mb-3">
                    <label for="phone">Remarks</label>
                    <textarea name="remark"  class="form-control" v-model="remark" placeholder="" id=""  textarea/>

                </div> -->
                <div style="text-align: center;margin-top: 5%;">
               
                <div class="form-group first" style="text-align: center;">
                  
                    <h6 style="color:#AF0A0C ;">Privacy Policy</h6>
                    <label style="font-size: small;">We do not collect or sell your <br> information for any 3rd party use</label>
                </div>
                </div>

            </div>
            <div class="col-md-6 text-center order-1 order-md-2" style="text-align: center;">
                <div class="img-half"><img src="images/eBook.png" /></div>
                <div class="form-group first">
                    <label style="color:#AF0A0C ;"><h4><b>BOOST YOUR PHARMACY STORE SALES WITH FREE EBOOK!</b></h4></label>
                    <label>Free eBook with 100 Sales Growth Tips<br> Get your guide to Success Now!</label>
                </div>

                <div class="form-group first" style="margin-bottom: 5%;">
                    <label class="new-label">Find easy ways to bring<br> more customers to your shop</label>
                    
                </div>
                <div class="form-group first" style="margin-bottom: 5%;">
                    <label class="new-label">Learn simple tricks to perform<br> better than competitors in sales</label>
                    
                </div>
                <div class="form-group first" style="margin-bottom: 5%;">
                    <label class="new-label">Get tips on using Simple & Free<br> online tools to sell to more customers</label>
                    
                </div>
                
            </div>
        </div>
    </div>
   
</template>

<script >
import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
 
export default {
        name: 'QuickReg',
        data() {
            return {
                username: '',
                email: '',
                phone: '',
            }
        },
        methods: {
            async Submit() {
				axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
				axios.defaults.headers.post['Accept'] = 'application/json';
                const submit_data = {
                    name: this.username,
                    email: this.email,
                    phone: this.phone,
                    
                }
               
                let result = await axios.post("/app/EbookSalesGrowth/eyJhbGciOiJIUz786iIsInR5cCI6IkpXVCJ9", submit_data);
                if (result.data.status == true) {
                    window.open('https://api.whatsapp.com/send?phone=918882702218&text=Hi!', '_blank');
                    window.open('https://web.whatsapp.com/send?phone=918882702218&text=Hi!', '_blank');
                    this.$router.push('/Thanks');
                } else {
                    alert(result.data.message);
                }
                
            }
        }
    }
</script>